const imeiStorageKey = "_imei";
import {setToken} from "@/util/auth";
export function getImei() {
    return sessionStorage.getItem(imeiStorageKey)
}
export function setImei(imei) {
    return sessionStorage.setItem(imeiStorageKey,imei)
}

const saasIdStorageKey = "_saasId";
export function getSaasId() {
    return sessionStorage.getItem(saasIdStorageKey)
}
export function setSaasId(saasId) {
    return sessionStorage.setItem(saasIdStorageKey,saasId)
}
export function readCommonParam(routeQuery){
    if(routeQuery._token){
        setToken(routeQuery._token)
    }

    let imei = routeQuery._imei;
    if(imei)
        setImei(imei)
    let saasId = routeQuery._saasId;
    if(saasId)
        setSaasId(saasId)
}
